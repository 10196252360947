<template>
    <div class="wrap">
        <div class="area editContent">
            <!-- <h1>{{title}}</h1> -->
            <div class="con mt16" v-html="con"></div>
        </div>
        
    </div>
</template>

<script>
export default {
    data(){
        return {
            agreeList:{
                '300001':'用户协议',
                '300002': "隐私协议",
                '300003': "服务协议",
                '300004': "知情同意书",
                '300005': "到店自取协议",
                '300006': "药品配送服务协议"  
            },
            title:'',
            con:''
        }
    },
    created(){
        this.getContract()
    },
    methods:{
        getContract(){
            this.$axios({
                type:'post',
                url:'common/getContract',
                data:{
                    type:this.$route.query.type,
                    //  type:'100001'
                }
            }).then(res=>{
                console.log(res)
                this.title = res.d.title,
                this.con = res.d.content
                this.$.setTitle(this.agreeList[this.$route.query.type])
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.wrap {
    .area {
        background: #fff;
        margin:0 8px;
        overflow: auto;
        border-radius: 8px;
        padding: 10px 12px;
       
        h1 {
            text-align: center;
            // font-size: 16px;
            color: #333;
        }
        .con {
            color: #666;
            // font-size: 14px;
        }
    }
   
}
</style>
